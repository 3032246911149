import React, { useEffect, useState } from 'react'
import LeftMenu from '../../components/left-menu/left-menu.component'
import RightMenu from '../../components/right-menu/right-menu.component'
import InteractiveMap from '../../components/map/map.component'
import styled from 'styled-components'
import { getMarkers, getRegionList, getSettlementTree } from '../../requests/kato.request'
import { kostanayDistricts } from '../../constants/snp.constant'
import { useParams } from 'react-router-dom';

import { IMarker, IRegion } from '../../interfaces/snp.interface'
import TestVersion from '../../components/test-version.component'
import { getLang } from '../../utils/helpers.utils'
import { navLinks } from '../../constants/navlinks.constant'

const HomeWrapper = styled.div`
  display: flex;
  max-height: 100%;
  .absolute {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;
  };
`
const Home = () => {
  const { kato, link, tab } = useParams();
  const [selectedLink, setSelectedLink] = useState<string | null>(null);
  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [regionList, setRegionList] = useState<IRegion[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<IRegion>();
  const [selectedMarker, setSelectedMarker] = useState<IMarker | null>(null);

  const [zoomedRegion, setZoomedRegion] = useState<IRegion | null>(null);
  const [isTest, setIsTest] = useState(false);

  useEffect(() => {
    if (navLinks.includes(link as any)) {
      setSelectedLink(link as any);
    } else {
      getRegionList().then((res) => {
        const list = res.map((item) => ({ ...kostanayDistricts.find(district => district.kato === item.kato), ...item, }));
        setRegionList(list);
        if (kato) {
          getSettlementTree(getLang().toUpperCase() as 'RU' | 'KZ').then((response) => {
            const children = Object.values(response.data)
              .flatMap((item) => Object.values(item).flatMap(item => item))
            const found = children.find((item) => item.kato === +kato)
            if (found && found.ppKato) {
              const coordinates = kostanayDistricts.find(district => district.kato === found.ppKato);
              const val = res.find(item => item.kato === found.ppKato)

              if (val && coordinates) {
                const region: IRegion = { ...val, ...coordinates };
                if (region && region.nameRu) {
                  setSelectedRegion(region);
                  setZoomedRegion(region);

                  getMarkers(found.ppKato).then((res) => {
                    setMarkers(res);
                    const marker = res.find(item => item.code === +kato);
                    if (marker) {
                      setSelectedMarker(marker);
                    }
                  });
                }
              }
            }

          })
        } else {
          getMarkers(0).then((res) => {
            setMarkers(res);
          });
        }

      });
    }
  }, [kato, link])

  useEffect(() => {
    if (selectedRegion) {
      getMarkers(selectedRegion.kato).then((res) => {
        setMarkers(res);
      })

      if (!kato) {
        setSelectedMarker(null);
      }
    }

  }, [selectedRegion, kato])

  useEffect(() => {
    window.location.hostname.includes('abat-kost.kz') ? setIsTest(false) : setIsTest(true);
  }, [])

  useEffect(() => {
    if (kato && navLinks.map(item => item.name).includes(kato as any)) {
      setSelectedLink(kato as any);
    } else if (link && navLinks.map(item => item.name).includes(link as any)) {
      setSelectedLink(link as any);
    }
  }, [kato, link])

  return (
    <HomeWrapper>
      {isTest && <div className='absolute'><TestVersion /></div>}
      <LeftMenu
        selectedLink={selectedLink}
        setSelectedLink={setSelectedLink}
        selectedRegion={selectedRegion!}
        setSelectedRegion={setSelectedRegion}
        selectedMarker={selectedMarker!}
        setSelectedMarker={setSelectedMarker}
        markers={markers}
        setZoomedRegion={setZoomedRegion}
      />
      <RightMenu
        selectedLink={selectedLink}
        setSelectedLink={setSelectedLink}
        selectedMarker={selectedMarker}
        selectedRegion={selectedRegion}
      />
      {regionList.length > 0 && <InteractiveMap
        markers={markers}
        setSelectedRegion={setSelectedRegion}
        setSelectedMarker={setSelectedMarker}
        regionList={regionList}
        zoomedRegion={zoomedRegion}
      />}
    </HomeWrapper>
  )
}

export default Home