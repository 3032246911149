import React, { useRef, useEffect, useState } from 'react';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import styled from 'styled-components';
import Extent from '@arcgis/core/geometry/Extent';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from '@arcgis/core/Graphic';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol';
import Point from '@arcgis/core/geometry/Point';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Zoom from '@arcgis/core/widgets/Zoom';
import Star from '../../assets/icons/outline-star.svg'
import YellowStar from '../../assets/icons/yellow-star.svg'
import GreenStar from '../../assets/icons/green-star.svg'

import { IMarker, IRegion } from '../../interfaces/snp.interface';

import { useTranslation } from 'react-i18next';
import { getLang } from '../../utils/helpers.utils';
import { useParams, useNavigate } from "react-router-dom";

const featureLayer = new FeatureLayer({
  url: 'https://services5.arcgis.com/XhZs5lLsWDmZpTlD/arcgis/rest/services/Kazakhstan_national_large_biodiversity_cores_Top20/FeatureServer',
  opacity: 0.9,
});

const MapContainer = styled.div`
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  .esri-ui-manual-container{
    display: none;
  }

  .esri-ui-top-left {
    display: none;
  }

  .esri-icon-plus:before {
    content: '+';
  }
  .esri-icon-minus:before {
    content: '-';
  }
`;

interface IProps {
  markers: IMarker[];
  setSelectedRegion: (region: IRegion) => void;
  setSelectedMarker: (marker: IMarker) => void;
  regionList: IRegion[];
  zoomedRegion?: IRegion | null;
}

const InteractiveMap: React.FC<IProps> = ({ markers, setSelectedRegion, setSelectedMarker, regionList, zoomedRegion }) => {
  const mapDivRef = useRef<HTMLDivElement | null>(null);
  const map = useRef<Map | null>(null);
  const mapView = useRef<MapView | null>(null);
  const graphicsLayer = useRef<GraphicsLayer | null>(null);
  const markerGraphicsLayer = useRef<GraphicsLayer | null>(null);
  const [currentZoom, setCurrentZoom] = useState<number>(7);
  const [showRegions, setShowRegions] = useState<boolean>(true);
  const { kato } = useParams()
  const navigate = useNavigate()

  const { i18n: { language } } = useTranslation();

  const loadRegions = () => {
    regionList.forEach((district) => {
      const [longitude, latitude] = district.coordinates;
      const pointGeometry = new Point({ longitude, latitude });

      const textSymbol = new TextSymbol({
        color: [0, 0, 0],
        haloColor: [255, 255, 255],
        haloSize: 1,
        text: district[`name${getLang()}`].replace(' ', '\n'),
        font: {
          size: 8,
          family: "Arial",
        },
      })

      const textGraphic = new Graphic({
        geometry: pointGeometry,
        symbol: textSymbol,
        attributes: { name: district[`name${getLang()}`] },
      });

      if (!showRegions) {
        graphicsLayer.current?.add(textGraphic);
        setShowRegions(true)
      };

    })

    if (currentZoom < 7) {
      graphicsLayer.current?.removeAll();
      setShowRegions(false);
    }
  }

  useEffect(() => {
    if (!mapDivRef.current) return;

    map.current = new Map({
      basemap: 'osm',
    });

    mapView.current = new MapView({
      container: mapDivRef.current,
      map: map.current,
      center: [63.3649, 53.2190],
      zoom: 7,
      constraints: {
        geometry: new Extent({
          xmin: 58.0,
          ymin: 42.0,
          xmax: 70.0,
          ymax: 58.0,
          spatialReference: new SpatialReference({ wkid: 4326 }),
        }),
        minZoom: 6,
      },
    });

    graphicsLayer.current = new GraphicsLayer();
    markerGraphicsLayer.current = new GraphicsLayer();
    map.current?.add(graphicsLayer.current);
    map.current?.add(markerGraphicsLayer.current);

    featureLayer.definitionExpression = "Region = 'Kostanay'";

    // featureLayer.load();

    // map.current?.add(featureLayer);

    const zoom = new Zoom({
      view: mapView.current,
    });

    mapView.current.ui.add(zoom, 'bottom-right');

    const zoomChangeHandler = mapView.current.watch('zoom', (newZoomLevel) => {
      setCurrentZoom(newZoomLevel);
    });

    regionList.forEach((district) => {
      const [longitude, latitude] = district.coordinates;
      const pointGeometry = new Point({ longitude, latitude });

      const textSymbol = new TextSymbol({
        color: [0, 0, 0],
        haloColor: [255, 255, 255],
        haloSize: 1,
        text: district[`name${getLang()}`].replace(' ', '\n'),
        font: {
          size: 8,
          family: "Arial",
        },
      })

      const textGraphic = new Graphic({
        geometry: pointGeometry,
        symbol: textSymbol,
        attributes: { name: district[`name${getLang()}`] },
      });

      graphicsLayer.current?.add(textGraphic);
    })

    const clickHandler = mapView.current?.on('click', (event: any) => {
      if (event.button === 2) return;
      mapView.current!.hitTest(event).then((response: any) => {
        const graphics = response.results;
        if (graphics.map((item: any) => item.layer.type).includes('graphics')) {
          const snp = (graphics.find((graphic: any) => graphic.layer.type === 'graphics') as any).graphic.attributes;
          if (snp.name && !snp.fillingStatus) {
            const region = regionList.find((region) => region[`name${getLang()}`] === snp.name);
            if (region) {
              setSelectedRegion(region);
              mapView.current?.goTo({ target: event.mapPoint, zoom: 9 });
            }
          }

          if (snp.fillingStatus === 'NOT_STARTED') {
            return;
          }

          setSelectedMarker(snp);
          if (snp.code) {
            navigate(`/${snp.code}`);
          }
          localStorage.setItem('snp', JSON.stringify({ kato: snp.code }));
        }
      });

      // featureLayer.queryFeatures({
      //   geometry: event.mapPoint,
      //   spatialRelationship: 'intersects',
      //   outFields: ['*'],
      //   returnGeometry: true,
      //   outSpatialReference: new SpatialReference({ wkid: 4326 }),
      // }).then((result) => {
      //   if (result.features[0] && regionList.find((region) => region.NAME_EN === result.features[0].attributes.NAME_EN)) {
      //     mapView.current?.goTo({ target: result.features[0].geometry.extent.center, zoom: 9 });
      //     const foundRegion = regionList.find((region) => region.NAME_EN === result.features[0].attributes.NAME_EN)

      //     if (!foundRegion) return;

      //     setSelectedRegion(foundRegion);

      //     const snpInfo = JSON.parse(localStorage.getItem('snp') as string);

      //     if (snpInfo && snpInfo.ppKato && snpInfo.ppKato !== foundRegion.kato) {
      //       localStorage.setItem('snp', JSON.stringify({ ppKaTo: foundRegion.kato }))
      //     } else {
      //       localStorage.setItem('snp', JSON.stringify({ ...snpInfo, ppKato: foundRegion.kato }));
      //     }
      //   }
      // })
    })

    const doubleClickHandler = mapView.current?.on('double-click', (event: any) => {
      event.stopPropagation();
    });

    const rightClickHandler = mapView.current?.on('drag', (event: any) => {
      if (event.button === 2) {
        event.stopPropagation();
      }
    });

    const hoverHandler = mapView.current?.on('pointer-move', (event: any) => {
      mapView.current!.hitTest(event).then((response: any) => {
        const graphics = response.results;
        if (graphics.map((item: any) => item.layer.type).includes('graphics')) {
          const snp = (graphics.find((graphic: any) => graphic.layer.type === 'graphics') as any).graphic.attributes;
          if (snp) {
            if (mapView.current) {
              if (snp.fillingStatus !== 'NOT_STARTED') {
                mapView.current.container.style.cursor = 'pointer';
              }
            }
          }
        } else {
          if (mapView.current) {
            mapView.current.container.style.cursor = 'default';
          }
        }
      });
    })


    return () => {
      if (zoomChangeHandler) {
        zoomChangeHandler.remove();
      }

      if (clickHandler) {
        clickHandler.remove();
      }

      if (doubleClickHandler) {
        doubleClickHandler.remove();
      }

      if (rightClickHandler) {
        rightClickHandler.remove();
      }

      if (hoverHandler) {
        hoverHandler.remove();
      }
    };
  }, [language, regionList, setSelectedMarker, setSelectedRegion, kato]);

  useEffect(() => {
    if (kato && zoomedRegion) {
      setTimeout(() => {
        mapView.current?.goTo({ target: zoomedRegion.coordinates, zoom: 9 });
      }, 500)
    }
  }, [kato, zoomedRegion])

  useEffect(() => {
    if (!markers.length) return;

    markerGraphicsLayer.current?.removeAll();

    markers.forEach((city) => {
      const { longitude, latitude } = city;
      const pointGeometry = new Point({ longitude: +longitude, latitude: +latitude });
      let markerSymbol: PictureMarkerSymbol | SimpleMarkerSymbol;

      if (city.isSupport) {


        markerSymbol = new PictureMarkerSymbol({
          url: city.fillingStatus === 'IN_PROCESS' ? YellowStar : city.fillingStatus === 'COMPLETED' ? GreenStar : Star,
          height: 16,
          width: 16,
        })
      } else {
        markerSymbol = new SimpleMarkerSymbol({
          style: 'circle',
          color: getBackgroundColor(city),
          size: 10,
          outline: {
            color: '#636363',
            width: 1.5,
            type: 'simple-line'
          }
        });
      }



      const markerGraphic = new Graphic({
        geometry: pointGeometry,
        symbol: markerSymbol,
        attributes: { ...city, type: 'city' },
      });

      let arr = [markerGraphic]

      if (!["Рудный Г.А.", "Костанайский район", "Аркалык Г.А."].includes(city.nameRu)) {
        const textSymbol = new TextSymbol({
          color: city.fillingStatus !== 'NOT_STARTED' || city.requiredFilling === false ? [0, 0, 0] : [100, 100, 100],
          haloColor: city.fillingStatus !== 'NOT_STARTED' || city.requiredFilling === false ? [255, 255, 255] : 'transparent',
          haloSize: 1,
          text: city[`name${getLang()}`],
          xoffset: 0,
          yoffset: -15,
          font: {
            size: 10,
            family: "Arial",
          }
        })

        const textGraphic = new Graphic({
          geometry: pointGeometry,
          symbol: textSymbol,
          attributes: { ...city, type: 'city' },
        });

        arr = [markerGraphic, textGraphic];
      }

      markerGraphicsLayer.current?.addMany(arr);

    });

    if (currentZoom < 9) {
      markerGraphicsLayer.current?.removeAll();
    }

    loadRegions();
  }, [currentZoom, markers])

  const getBackgroundColor = (city: any) => {
    if (city.requiredFilling === false) {
      return [220, 220, 220];
    }

    switch (city.fillingStatus) {
      case 'IN_PROCESS':
        return [255, 255, 0];
      case 'COMPLETED':
        return [0, 255, 0];
      case 'NOT_STARTED':
        return [220, 220, 220];
    }


  }

  return <MapContainer ref={mapDivRef} style={{ width: '100%', height: '100%', minHeight: '500px' }} />;
};

export default InteractiveMap;
