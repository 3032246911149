import React from 'react'

import { SnpContainer } from './index.styles';
import { useTranslation } from 'react-i18next';

import { checkVal } from '../../utils/helpers.utils';
import styled from 'styled-components';
import { Building, Employment, Population, Road } from '../../assets/icons';
import { Text } from '../text.component';

const Container = styled.div<{ bgColor?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.3125rem;
  background-color: ${({ bgColor }) => bgColor ? bgColor : 'white'};
  gap: 0.3125rem;
  border-radius: 10px;
  text-align: center;

  .list {
    display: flex;
    gap: 0.625rem;
    justify-content: space-between;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  gap: 0.625rem;

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DBDCE0;
    border-radius: 5px;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .info {
    display: flex;
    gap: 0.625rem;
    text-transform: uppercase;
    align-items: center;
  }

  .road-heading {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;
    justify-content: center;
    text-align: left;
  }
`;

const Bordered = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  border: 1px solid #DBDCE0;
  border-radius: 5px;
  padding: 0.5rem 0.3125rem;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  info: { [key: string]: any };
}

const SnpInfo: React.FC<IProps> = ({ info }) => {
  const { t } = useTranslation();

  if (!info) return null;

  return (
    <SnpContainer>
      {
        Object.values(info.population).some(item => checkVal(item)) && <Container>
          {
            checkVal(info.population.all) &&
            <Header>
              <div className="icon"><Population /></div>
              <div className='info'>
                <Text fontSize='0.75rem' fontWeight={400} color='#202224'>{t('form.population')}</Text>
                <Text fontSize='1.5625rem' fontWeight={400} color="#2366CA">{info.population.all.toLocaleString('ru-RU')}</Text>
              </div>
            </Header>
          }

          {/* <div className='list'>
            {info.population && Object.keys(info.population) && Object.keys(info.population).filter(key => key !== 'all' && checkVal(info.population[key])).map((key) => (
              <Bordered>
                <Text fontSize='0.75rem' fontWeight={400}>{t(`form.populationObj.${key}`)}</Text>
                <Text fontSize='1rem' fontWeight={400} color="#2366CA">{info.population[key].toLocaleString('ru-RU')}</Text>
              </Bordered>
            ))}
          </div> */}
        </Container>
      }

      {
        Object.values(info.employment).some(item => checkVal(item)) && <Container>
          {
            checkVal(info.employment.employed) &&
            <Header>
              <div className="icon"><Employment /></div>
              <div className='info'>
                <Text fontSize='0.75rem' fontWeight={400} color='#202224'>{t('form.employmentObj.employed')}</Text>
                <Text fontSize='1.5625rem' fontWeight={400} color="#2366CA">{info.employment.employed.toLocaleString('ru-RU')}</Text>
              </div>
            </Header>
          }

          <div className='list'>
            {Object.keys(info.employment).filter(key => key !== 'employed' && checkVal(info.employment[key])).map((key) => (
              <Bordered>
                <Text fontSize='0.75rem' fontWeight={400}>{t(`form.employmentObj.${key}`)}</Text>
                <Text fontSize='1rem' fontWeight={400} color="#2366CA">{info.employment[key].toLocaleString('ru-RU')}</Text>
              </Bordered>
            ))}
          </div>
        </Container>
      }

      {
        checkVal(info?.buildingNumber) && <Container >
          <Header>
            <div className="icon"><Building /></div>
            <div className='info'>
              <Text fontSize='0.75rem' fontWeight={400} color='#202224'>{t('living')}</Text>
              <Text fontSize='1.5625rem' fontWeight={400} color="#2366CA">{info?.buildingNumber.toLocaleString('ru-RU')}</Text>
            </div>
          </Header>
        </Container>
      }

      {
        Object.values(info?.regionRoad).some(item => checkVal(item)) && <Container>
          {
            checkVal(info?.regionRoad.distance) &&
            <Header>
              <div className="icon"><Road /></div>
              <div className='info'>
                <Text fontSize='1.5625rem' fontWeight={400} color="#2366CA">{info?.regionRoad.distance.toLocaleString('ru-RU')}</Text>
                <div className='road-heading'>
                  <Text fontSize='0.75rem' fontWeight={400}>км</Text>
                  <Text fontSize='0.75rem' fontWeight={400} color='#606367'>{t(`form.regionRoad.title`)}</Text>
                </div>
              </div>
            </Header>
          }

          {
            <Bordered>
              <Text fontSize='0.75rem' color='#606367' fontWeight={600}>
                {info[`regionRoad`].quality && `${t('snp-condition')} ${t(`form.roads.${info[`regionRoad`].quality}`).toLowerCase()}, `}
                {info[`regionRoad`].cover && t(`form.roads.${info[`regionRoad`].cover}`).toLowerCase()}
              </Text>
            </Bordered>
          }
        </Container>
      }

      {
        Object.values(info.areaRoad).some(item => checkVal(item)) && <Container>
          {
            checkVal(info.areaRoad.distance) &&
            <Header>
              <div className="icon"><Road /></div>
              <div className='info'>
                <Text fontSize='1.5625rem' fontWeight={400} color="#2366CA">{info.areaRoad.distance.toLocaleString('ru-RU')}</Text>
                <div className='road-heading'>
                  <Text fontSize='0.75rem' fontWeight={400}>км</Text>
                  <Text fontSize='0.75rem' fontWeight={400} color='#606367'>{t(`form.areaRoad.title`)}</Text>
                </div>
              </div>
            </Header>
          }

          {
            <Bordered>
              <Text fontSize='0.75rem' color='#606367' fontWeight={600}>
                {info[`areaRoad`].quality && `${t('snp-condition')} ${t(`form.roads.${info[`areaRoad`].quality}`).toLowerCase()}, `}
                {info[`areaRoad`].cover && t(`form.roads.${info[`areaRoad`].cover}`).toLowerCase()}
              </Text>
            </Bordered>
          }
        </Container>
      }
    </SnpContainer>
  )
}

export default SnpInfo